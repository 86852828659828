import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Hero from '../components/hero'
import Intro from '../components/intro'

import SEOPage from '../components/SEO/Page'

class ChildrenPage extends React.Component {
  render() {
    const { location, data } = this.props

    const childrenContent = data.prismicChildren.data

    var heroImgs = []
    var heroImgsMobile = []

    childrenContent.hero.forEach(item => {
      heroImgs.push(item.desktop_image.url)
      heroImgsMobile.push(item.mobile_image.url)
    })

    var link = {
      url: childrenContent.link.url,
      title: childrenContent.link_title
    }

    return (
      <Layout location={location}>
        <SEOPage
          title={childrenContent.title && childrenContent.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />
        <Intro
          id="requirements"
          title={childrenContent.lead_title}
          lead={childrenContent.lead_text.html}
          embed={childrenContent.embed.html}
          slides={childrenContent.slides}
          link={link}
        />
      </Layout>
    )
  }
}

export const pageChildrenQuery = graphql`
  query {
    prismicChildren {
      data {
        title
        hero {
          desktop_image {
            url
            alt
          }
          mobile_image {
            url
            alt
          }
        }
        lead_title
        lead_text {
          html
        }
        slides {
          slides_image {
            url
            alt 
          }
        }
        embed {
          html
        }
        link_title
        link {
          url
        }
      }
    }
  }
`

export default ChildrenPage
